import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmarkCircle,
  faCheckCircle,
  faInfoCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

import { default as SnackbarMui, SnackbarOrigin } from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

interface SnackbarProps {
  snackbarKey: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
  variant?: "info" | "success" | "error";
  direction?: SlideProps["direction"];
  vertical?: SnackbarOrigin["vertical"];
  horizontal?: SnackbarOrigin["horizontal"];
}

function Snackbar({
  snackbarKey,
  text,
  isOpen,
  onClose,
  variant = "info",
  direction = "up",
  vertical = "bottom",
  horizontal = "center",
}: SnackbarProps) {
  type TransitionProps = Omit<SlideProps, "direction">;

  const [backgroundColor, setBackgroundColor] = useState("#343a40");
  const [icon, setIcon] = useState<IconDefinition>(faInfoCircle);

  useEffect(() => {
    switch (variant) {
      case "success":
        setBackgroundColor("#28a745");
        setIcon(faCheckCircle);
        break;

      case "error":
        setBackgroundColor("#dc3545");
        setIcon(faXmarkCircle);
        break;
      default:
        break;
    }
  }, [variant]);

  return (
    <SnackbarMui
      open={isOpen}
      onClose={onClose}
      TransitionComponent={(props: TransitionProps) => {
        return (
          <Slide
            {...props}
            style={{ backgroundColor: backgroundColor }}
            direction={direction}
          />
        );
      }}
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      message={
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            fontSize: "17px",
          }}
        >
          <FontAwesomeIcon icon={icon} />
          <span>{text}</span>
        </div>
      }
      autoHideDuration={4000}
      transitionDuration={300}
      key={snackbarKey}
      sx={{ display: () => (isOpen ? "block" : "none") }}
    />
  );
}

export default Snackbar;
